import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="299" height="10" viewBox="0 0 299 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5888 10C49.4071 9.07169 4.14324 10.1081 0.853199 9.82831C-1.83281 9.45317 2.45967 2.84689 4.95291 1.87407C5.82682 1.52436 6.84211 1.47351 9.16827 1.63247C12.8567 1.93131 60.5495 1.33997 60.5495 1.33997C61.1793 1.54344 63.0685 0.856744 63.4155 1.10472C64.5079 1.68332 141.811 0.894891 148.404 0.8631C152.401 0.844025 149.239 1.23824 153.943 1.07928C163.967 0.761364 185.815 1.06657 193.411 0.704142C199.49 0.405302 200.801 0.392594 206.635 0.63421C212.598 0.850392 244.304 0.538832 247.928 0.074676C248.815 -0.0524901 249.496 -0.00797814 249.496 0.138263C249.496 0.170054 271.845 0.278142 271.948 0.392591C272.115 0.538832 272.796 0.608769 273.426 0.507036C274.595 0.354437 298.384 -0.198737 298.757 0.227269C299.926 1.44806 296.649 6.64915 293.886 7.91446C291.058 9.26878 275.533 7.74915 263.054 8.56302L204.952 8.64568L192.498 9.15434C191.072 8.71561 185.584 9.82196 182.268 9.03353C180.868 8.72197 170.972 8.67746 168.787 9.00809C166.885 9.26242 116.262 8.62023 111.07 9.50403C101.816 8.76647 64.2894 9.82197 55.5888 10Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="204" height="10" viewBox="0 0 204 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.9268 10C33.7092 9.07169 2.82683 10.1081 0.582116 9.82831C-1.25048 9.45317 1.67817 2.84689 3.37924 1.87407C3.97549 1.52436 4.6682 1.47351 6.25528 1.63247C8.77181 1.93131 41.3114 1.33997 41.3114 1.33997C41.741 1.54344 43.03 0.856744 43.2667 1.10472C44.0121 1.68332 96.7541 0.894891 101.252 0.8631C103.979 0.844025 101.822 1.23824 105.031 1.07928C111.871 0.761364 126.777 1.06657 131.959 0.704142C136.107 0.405302 137.001 0.392594 140.982 0.63421C145.05 0.850392 166.682 0.538832 169.155 0.074676C169.76 -0.0524901 170.225 -0.00797814 170.225 0.138263C170.225 0.170054 185.473 0.278142 185.543 0.392591C185.657 0.538832 186.122 0.608769 186.551 0.507036C187.349 0.354437 203.58 -0.198737 203.834 0.227269C204.632 1.44806 202.396 6.64915 200.511 7.91446C198.582 9.26878 187.989 7.74915 179.475 8.56302L139.833 8.64568L131.337 9.15433C130.363 8.71561 126.619 9.82196 124.357 9.03353C123.401 8.72197 116.65 8.67745 115.159 9.00809C113.861 9.26242 79.3225 8.62023 75.78 9.50403C69.4668 8.76647 43.863 9.82197 37.9268 10Z" fill="#FFA700" />
    </svg>
  </span>
)
