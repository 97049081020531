import React from "react"

export const TitleUnderlineVozOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="110" height="11" viewBox="0 0 110 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.285 10.0689C18.0325 9.13289 1.51264 10.1132 0.313085 9.82931C-0.665741 9.45085 0.922992 2.84992 1.83606 1.8802C2.15613 1.53158 2.52677 1.48199 3.37503 1.64383C4.71989 1.94724 22.1246 1.41504 22.1246 1.41504C22.3536 1.61928 23.0453 0.934935 23.1711 1.18334C23.5678 1.7633 51.7776 1.07071 54.1835 1.04709C55.6419 1.03298 54.487 1.42327 56.2039 1.27014C59.8628 0.964657 67.8338 1.29694 70.6066 0.94394C72.8257 0.652638 73.3041 0.641556 75.4323 0.890405C77.6074 1.11398 89.1774 0.841731 90.5015 0.382071C90.8255 0.256005 91.0739 0.301362 91.0734 0.447602C91.0733 0.479393 99.2279 0.61519 99.265 0.729766C99.3255 0.876213 99.5738 0.946994 99.8039 0.846042C100.231 0.694894 108.913 0.171218 109.048 0.597684C109.47 1.81992 108.257 7.01692 107.244 8.27879C106.208 9.62959 100.548 8.09073 95.9921 8.88912L74.7907 8.89974L70.2449 9.39295C69.7258 8.95246 67.7197 10.052 66.5125 9.25946C66.0024 8.94617 62.3916 8.88939 61.5933 9.21731C60.8984 9.46928 42.4287 8.76433 40.5312 9.64169C37.1572 8.89266 23.4604 9.90162 20.285 10.0689Z" fill="#FFBA00"/>
</svg>

  </span>
)

export const TitleUnderlineVozTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="299" height="10" viewBox="0 0 299 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5888 10C49.4071 9.07169 4.14324 10.1081 0.853199 9.82831C-1.83281 9.45317 2.45967 2.84689 4.95291 1.87407C5.82682 1.52436 6.84211 1.47351 9.16827 1.63247C12.8567 1.93131 60.5495 1.33997 60.5495 1.33997C61.1793 1.54344 63.0685 0.856744 63.4155 1.10472C64.5079 1.68332 141.811 0.894891 148.404 0.8631C152.401 0.844025 149.239 1.23824 153.943 1.07928C163.967 0.761364 185.815 1.06657 193.411 0.704142C199.49 0.405302 200.801 0.392594 206.635 0.63421C212.598 0.850392 244.304 0.538832 247.928 0.074676C248.815 -0.0524901 249.496 -0.00797814 249.496 0.138263C249.496 0.170054 271.845 0.278142 271.948 0.392591C272.115 0.538832 272.796 0.608769 273.426 0.507036C274.595 0.354437 298.384 -0.198737 298.757 0.227269C299.926 1.44806 296.649 6.64915 293.886 7.91446C291.058 9.26878 275.533 7.74915 263.054 8.56302L204.952 8.64568L192.498 9.15434C191.072 8.71561 185.584 9.82196 182.268 9.03353C180.868 8.72197 170.972 8.67746 168.787 9.00809C166.885 9.26242 116.262 8.62023 111.07 9.50403C101.816 8.76647 64.2894 9.82197 55.5888 10Z" fill="#FFBA00" />
    </svg>
  </span>
)