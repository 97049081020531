import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"



const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Enrutamientos inteligentes",
      description: "Dirige las llamadas de tus clientes al agente más adecuado desde el primer momento con nuestro enrutamiento inteligente. Mejora los tiempos de respuesta y la satisfacción del cliente gestionando las llamadas de manera eficiente y efectiva.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Automatizaciones en recontacto",
      description: "Nunca pierdas la oportunidad de seguir conectado con tus clientes. Con nuestras automatizaciones en recontacto, planifica llamadas de seguimiento que mantengan a tus clientes comprometidos y abran nuevas oportunidades de venta.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Llamadas a todo el mundo",
      description: "Expande tu alcance global sin preocuparte por los costos. Nuestro sistema VoIP te permite conectar con clientes alrededor del mundo a tarifas competitivas, asegurando que tu negocio no tenga límites.",
      image: imageThreeMobile,
      background: "#EEEAFB"
    },
    {
      title: "Supervisa tus llamadas en tiempo real",
      description: "Eleva la calidad de tu servicio al cliente con herramientas de supervisión en tiempo real. Analiza el desempeño de tus agentes, identifica áreas de mejora e implementa cambios que generen impacto positivo en la experiencia del cliente. ",
      image: imageFourMobile,
      background: "#FBE6E5"
    },
  ]

  return (
    <section className="container-experience-voiceApi">
      <p className="container-experience-voiceApi-title">
        Asegura la    <TitleUnderlineOne underline="continuidad de tu" /> <TitleUnderlineTwo underline="operación" /> indistintamente del volumen de llamadas de tus campañas
      </p>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsTwo">
        {/* columna */}
        <div className="container-experience-voiceApi-cardsTwo-cards-column">
          <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
            <section style={{ padding: "0px 32px" }}>
              <p className="container-experience-voiceApi-cardsTwo-card-title">Enrutamientos inteligentes</p>
              <p className="container-experience-voiceApi-cardsTwo-card-description">
                Dirige las llamadas de tus clientes al agente más adecuado desde el primer momento con nuestro enrutamiento inteligente. Mejora los tiempos de respuesta y la satisfacción del cliente gestionando las llamadas de manera eficiente y efectiva.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsTwo-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#FBE6E5", padding: "32px 30px 0px 0px" }}>
          <section style={{ padding: "0px 0px 0px 32px" }} >
            <p className="container-experience-voiceApi-cardsTwo-card-title">Automatizaciones en recontacto</p>
            <p className="container-experience-voiceApi-cardsTwo-card-description">
              Nunca pierdas la oportunidad de seguir conectado con tus clientes. Con nuestras automatizaciones en recontacto, planifica llamadas de seguimiento que mantengan a tus clientes comprometidos y abran nuevas oportunidades de venta.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsTwo-card-image"
              src={imageTwoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-voiceApi-cardsOne-card-title">Llamadas a todo el mundo</p>
            <p className="container-experience-voiceApi-cardsOne-card-description">
              Expande tu alcance global sin preocuparte por los costos. Nuestro sistema VoIP te permite conectar con clientes alrededor del mundo a tarifas competitivas, asegurando que tu negocio no tenga límites.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsOne-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-voiceApi-cardsOne-column">
          {/* columna */}
          <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#D3F7FA"}}>
            <section>
              <p className="container-experience-voiceApi-cardsOne-card-title">Supervisa tus llamadas en tiempo real</p>
              <p className="container-experience-voiceApi-cardsOne-card-description">
                Eleva la calidad de tu servicio al cliente con herramientas de supervisión en tiempo real. Analiza el desempeño de tus agentes, identifica áreas de mejora e implementa cambios que generen impacto positivo en la experiencia del cliente. 
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsOne-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      <div className="container-experience-voiceApi-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-voiceApi-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-voiceApi-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-voiceApi-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-voiceApi-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
